import React from "react";
import { motion } from "framer-motion";
import Icon2 from "../../../Images/parthners/wealthywomen.webp";
import Icon3 from "../../../Images/parthners/largobb.png";
import Icon4 from "../../../Images/parthners/rjenb.png";
import Icon7 from "../../../Images/parthners/humanlifeb.png";
import Icon8 from "../../../Images/parthners/StegLogo-removebg-preview.png";
import Icon9 from "../../../Images/parthners/tlc.png";
import BgImGAE from "../../../Images/ktnLogo/bg-image.svg";
import Gustav from "../../../Images/parthners/PHOTO-2024-05-01-21-33-18-removebg-preview.png";
import "./HomeHero.css";
import { useNavigate } from "react-router-dom";

const HomeHero = () => {
  const navigate = useNavigate();

  return (
    <section
      className="relative bg-gray-100 min-h-[80vh] md:min-h-[80vh] lg:min-h-[120vh] xl:min-h-[100vh] flex flex-col justify-between items-center py-16 sm:py-20 overflow-hidden"
      style={{ backgroundImage: `url(${BgImGAE})` }}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-2 2xl:px-0 flex-1 flex items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:px-5 xl:px-0">
          {/* Left Content */}
          <div className="lg:col-span-3">
            <motion.h1
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 2, ease: "easeOut" }}
              className="font-playfair max-w-6xl text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-semibold text-white mb-8 mt-20 2xl:mt-28 tracking-wide"
            >
              Providing <br /> Communication And Marketing Solutions For Builders Of
              Tomorrow
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5, ease: "easeOut", delay: 0.8 }}
              className="font-playfair xl:max-w-4xl 2xl:max-w-5xl text-md lg:text-xl xl:text-xl 2xl:text-3xl mb-12 sm:text-md md:text-2xl text-gray-300 2xl:mt-10"
            >
              Thought-leaders communicate and influence with compelling
              narratives; We're facilitating them with{" "}
              content, strategy, and systems.
            </motion.p>

            <motion.button
              onClick={() => navigate("/home#price")}
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5, ease: "easeOut", delay: 0.8 }}
              className="text-xl font-playfair bg-[#1d3072] text-white sm:text-base md:text-md 2xl:text-2xl px-5 py-3 rounded-md transition duration-300 will-change-transform hover:bg-[#949a84] hover:text-black hover:scale-105"
            >
              Partnership Offers
            </motion.button>
          </div>
        </div>
      </div>

      {/* Logos at the Bottom */}
      <div className="w-full max-w-6xl bg-opacity-90 py-6 px-4 mt-10 md:mt-16 2xl:mt-20 overflow-hidden">
  <div className="flex items-center gap-10 md:gap-20 animate-marquee">
    {[...Array(20)].map((_, repeatIndex) =>
      [Icon2, Icon3, Icon4, Icon7, Gustav, Icon8, Icon9].map((icon, index) => {
        // Set default size
        let sizeClass = "w-20 md:w-26";
        
        // Reduce size for Icon7 & Icon4
        if (icon === Icon7 || icon === Icon4) sizeClass = "w-10 md:w-12";
        
        // Increase size for Icon9
        if (icon === Icon9) sizeClass = "w-12 md:w-26"; 
        if (icon === Icon8) sizeClass = "w-14 md:w-26"; 

        return (
          <img
            key={`${repeatIndex}-${index}`}
            src={icon}
            alt={`Logo ${index + 1}`}
            className={`object-contain filter brightness-0 invert ${sizeClass}`}
          />
        );
      })
    )}
  </div>
</div>

    </section>
  );
};

export default HomeHero;
