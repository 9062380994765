const SidebarPostsConts = [
  {
    title: "From Idea to Publishing- What to Expect When you Work With a Ghostwriter",
    date: "December 20, 2024",
    url: "/ktn/afroprenuership/from-idea-to-publishing-what-to-expect-when-you-work-with a-ghostwriter",
  },
  {
    title: "Ready to Publish- 10 Signs You're Prepared to Hire a Ghostwriter",
    date: "December 13, 2024",
    url: "/ktn/afroprenuership/ten-signs-youre-prepared-to-hire-a-ghostwriter",
  },
  {
    title: "The Secret to a Bestselling Book in 2025: Why CEOs Choose to Work with Ghostwriters",
    date: "December 06, 2024",
    url: "/ktn/afroprenuership/the-secret-to-a-bestselling-book",
  },
  {
    title: "Why Top Executives Choose Ghostwriters",
    date: "November 29, 2024",
    url: "/ktn/afroprenuership/why-top-executives-choose-ghostwriters",
  },

  {
    title: "How a Ghostwriter Can Help You Establish Thought Leadership Through a Book",
    date: "November 22, 2024",
    url: "/ktn/afroprenuership/how-a-ghostwriter-can-help-you-establish-thought-leadership-through-a-book",
  },

  {
    title: "How to Create A Tempting Ghostwriting Offerr",
    date: "November 15, 2024",
    url: "/ktn/afroprenuership/how-to-create-a-tempting-ghostwriting-offer",
  },
  {
    title: "7 Proven Strategies To Earn More Money As A Ghostwriter",
    date: "November 08, 2024",
    url: "/ktn/afroprenuership/seven-proven-strategies-to-earn-more-money-as-a-ghostwriter",
  },

];

export default SidebarPostsConts;
