import TSSO from "../../Images/blogpics/afroprenueship/TSSO.webp";

const FeaturedPostConts = {
  title: "3 Success Stories of Entrepreneurs Who Partnered With Ghostwriters",
  date: "December 27, 2024",
  description:
    "An article on a leading business platform posed the question: \"Why haven’t you written a book yet?\" It highlighted several renowned entrepreneurs who have authored books, implying that if they could find the time, so could you...",
  image: TSSO,
  author: "Afropreneurship",
};

export default FeaturedPostConts;
