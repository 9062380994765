import { motion } from "framer-motion";
import Image1 from "../../Images/ktnLogo/logo_ktn.jpg";
import Image2 from "../../Images/ktnLogo/new.png";
import Image3 from "../../Images/ktnLogo/ktn_plus.png";
import BackgroundImage from "../../Images/ktnLogo/bg-image.svg";

const textVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.6, ease: "easeOut" },
  }),
};

const cardVariants = {
  hidden: { opacity: 0, x: -70 }, // Start further left
  visible: (i) => ({
    opacity: 1,
    x: 0, // Slide to original position
    transition: { delay: i * 0.5, duration: 1.2, ease: "easeInOut" }, // Slower and smoother
  }),
};


const InfoSection = () => {
  return (
    <section
      className="relative py-32 md:py-44 px-6 bg-cover bg-center font-playfair"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <div className="absolute inset-0 bg-black/60"></div>

      <div className="relative max-w-7xl mx-auto text-white">
        <div className="mb-16 max-w-4xl mx-auto text-center">
          <motion.h2
            className="text-3xl xl:text-6xl font-extrabold leading-tight tracking-tight"
            variants={textVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            custom={0}
          >
            Kick the {" "}
            
            <span className="text-red-600">
            Narrative
              </span> (KTN)<br /> is Our In-House Media Machine
          </motion.h2>

          <motion.p
            className="text-xl mt-8 leading-relaxed"
            variants={textVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            custom={1}
          >
            With KTN, we:
          </motion.p>

          <ul className="mt-2 space-y-5 text-lg max-w-lg mx-auto">
            {[
              "• Experiment with communication and marketing strategies.",
              "• Share what we learn after execution and feedback.",
              "• Showcase our philosophies and strategies.",
            ].map((text, i) => (
              <motion.li
                key={i}
                className="flex items-start gap-4 text-left"
                variants={textVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                custom={i + 2}
              >
                <span className="font-medium">{text}</span>
              </motion.li>
            ))}
          </ul>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        {
  [
    {
      title: "KTN",
      img: Image2,
      desc: "Kick & co's video journal, features content library with video series, KTN podcast, and original scripted programming.",
      but: "Watch KTN",
      link: "https://www.youtube.com/@KICKTHENARRATIVE",
    },
    {
      title: "KTN BLOG",
      img: Image1,
      desc: "Kick & co’s blog features insight shared in 3 distinct categories.",
      but: "Read KTN",
      link: "/readktn",
    },
    {
      title: "KTN PLUS",
      img: Image3,
      desc: "Behind the $4.99/month paywall, you’ll access weekly assets to make your brand grow authority, revenue, and reach.",
      but: "Join Waitlist",
      link: "https://wjqwrc5sl98.typeform.com/to/f6kAlycr",
    },
  ].map((item, index) => (
    <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
      <motion.div
        className="relative group rounded-3xl shadow-2xl overflow-hidden bg-gray-900 text-white hover:shadow-2xl transition-transform transform hover:-translate-y-2 duration-500"
        variants={cardVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        custom={index}
      >
        <div className="relative w-full h-80">
          <img
            src={item.img}
            alt={item.title}
            className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-black/70"></div>
        </div>

        <div className="absolute bottom-6 left-6 right-6">
          <h3 className="text-3xl font-semibold">{item.title}</h3>
          <p className="mt-3 text-gray-200">{item.desc}</p>
          <button className="mt-5 px-6 py-3 bg-white text-gray-900 font-semibold rounded-full shadow-lg hover:bg-gray-200 transition-all">
            {item.but}
          </button>
        </div>

        <div className="absolute top-0 left-0 w-20 h-20 bg-yellow-400 opacity-30 blur-3xl rounded-full group-hover:opacity-70"></div>
      </motion.div>
    </a>
  ))
}

        </div>
      </div>
    </section>
  );
};

export default InfoSection;
