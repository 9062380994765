import React, { useEffect } from "react";
import Image1 from "../../../Images/Evolved/chair.jpeg";
import Image2 from "../../../Images/Evolved/team_leads.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const CombinedEvolved = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  
  return (
    <div className="relative w-full min-h-screen bg-black flex flex-col items-center py-10 md:py-32 px-0 md:px-10 overflow-hidden font-playfair"  >
      {/* Header */}
      <div className="text-center mb-16 lg:mb-20" data-aos="fade-up">
        <span className="font-medium px-6 py-3 bg-[#1d3072] text-md md:text-lg 2xl:text-2xl text-white rounded-full">
          We've Evolved
        </span>
      </div>

      {/* Desktop Layout */}
      <div className="hidden md:grid grid-cols-[1fr_0.5fr_1fr] grid-rows-2 gap-x-6 gap-y-20 items-center text-white w-[90%] 2xl:w-[70%] mx-auto relative">
        {/* First Row */}
        <div className="flex justify-center items-center" data-aos="fade-right">
          <img src={Image1} alt="First Evolution Stage" className="w-full h-96 object-cover rounded" />
        </div>
        <div></div>
        <div data-aos="fade-left">
        <h2 className="text-2xl 2xl:text-4xl font-bold mb-10">The Genesis 
          </h2>
          <p className="text-gray-300 text-sm xl:text-lg">
          As usual, great things begin with a dream and a dreamer on a lonely path until they find meaning, purpose, and their tribe

Kick & co started as a one-man team working from this desk.

          </p>     
        </div>

        {/* Second Row */}
        <div data-aos="fade-right">
          <h2 className="text-2xl 2xl:text-4xl font-bold mb-10">The Tribe</h2>
          <p className="text-gray-300 text-sm xl:text-lg">
            Kick & Co is a team of believers who understand the necessity of properly representing narratives.
          </p>
          <br />
          <p className="text-gray-300 text-sm xl:text-lg">
            We provide communication and marketing solutions for builders of tomorrow because we know what it means to kick against the current and defy the negative narratives tied to one’s time and identity.
          </p>
          <br />
          <p className="text-gray-300 text-sm xl:text-lg">
            Our mission to be the acolytes of change-makers stems from our own journey.
            <br /> Revolutionary leaders inspire and influence with compelling narratives—rooted in their lived experiences—to craft legacies that shape the future.
          </p>
        </div>
        <div></div>
        <div className="flex justify-center items-center" data-aos="fade-left">
          <img src={Image2} alt="Second Evolution Stage" className="w-full h-96 object-contain rounded" />
        </div>
      </div>

      {/* Adjusted Vertical Line */}
      <div className="hidden md:block absolute left-1/2 transform -translate-x-1/2 top-[15%] bottom-[10%] w-[2px] bg-gray-500" data-aos="fade-in"></div>

      <div className="hidden md:block absolute left-2/2 transform -translate-x-1/2 -translate-y-1/2 top-[30%]" data-aos="zoom-in">
        <span className="bg-black px-2 text-white text-lg lg:text-3xl xl:text-5xl 2xl:text-7xl font-semibold">2021</span>
      </div>
      <div className="hidden md:block absolute left-2/2 transform -translate-x-1/2 -translate-y-1/2 top-[70%]" data-aos="zoom-in">
        <span className="bg-black px-2 text-white text-lg lg:text-3xl xl:text-5xl 2xl:text-7xl font-semibold">2025</span>
      </div>

      {/* Mobile Layout */}
      <div className="flex md:hidden w-full max-w-5xl mx-auto relative">
  {/* Continuous Timeline Background (starts from first year) */}
  <div className="absolute top-1/2 left-1/2 w-1/2 h-1 bg-white opacity-50"></div>

  <Swiper className="w-full">
    {/* First Slide (2021) */}
    <SwiperSlide>
      <div className="flex flex-col px-4 items-center text-white gap-8 relative" data-aos="fade-up">
        <img
          src={Image1}
          alt="First Evolution Stage"
          className="w-full h-72 object-cover rounded"
          data-aos="fade-right"
        />

        {/* Year Marker */}
        <div className="relative flex justify-center w-full">
          <span className="text-white text-3xl font-semibold bg-black px-4" data-aos="zoom-in">
            2021
          </span>
        </div>

        <div className="text-center px-4" data-aos="fade-left">
          <h2 className="text-xl font-bold mb-2">The Genesis</h2>
          <p className="text-gray-300 text-sm md:text-md">
          As usual, great things begin with a dream and a dreamer on a lonely path until they find meaning, purpose, and their tribe

Kick & co started as a one-man team working from this desk.          </p>
       
        </div>
      </div>
    </SwiperSlide>

    {/* Second Slide (2025) */}
    <SwiperSlide>
      <div className="flex flex-col items-center text-white gap-8 px-4 relative" data-aos="fade-up">
        
        {/* Fixed Horizontal Line (only for 2025) */}
        <div className="absolute top-1/2 left-0 w-full h-1 bg-white opacity-50"></div>

        <div className="text-center" data-aos="fade-right">
          <h2 className="text-xl font-bold mb-2">The Tribe</h2>
          <p className="text-gray-300 text-sm md:text-md">
            Kick & Co is a team of believers who understand the necessity of properly representing narratives.
          </p>
          <p className="text-gray-300 text-sm md:text-md mt-2">
            We provide communication and marketing solutions for builders of tomorrow because we know what it means to kick against the current and defy the negative narratives tied to one’s time and identity.
          </p>
          <p className="text-gray-300 text-sm md:text-md mt-2">
            Our mission to be the acolytes of change-makers stems from our own journey.
          </p>
          <p className="text-gray-300 text-sm md:text-md mt-2">
            Revolutionary leaders inspire and influence with compelling narratives—rooted in their lived experiences—to craft legacies that shape the future.
          </p>
        </div>

        {/* Year Marker */}
        <div className="relative flex justify-center w-full px-4">
          <span className="text-white text-3xl font-semibold bg-black px-4" data-aos="zoom-in">
            2025
          </span>
        </div>

        <img
          src={Image2}
          alt="Second Evolution Stage"
          className="w-full h-72 object-contain rounded"
          data-aos="fade-left"
        />
      </div>
    </SwiperSlide>
  </Swiper>
</div>



    </div>
  );
};

export default CombinedEvolved;