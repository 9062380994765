import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

const NewsletterPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the popup has already been shown in the session
    const isPopupShown = sessionStorage.getItem("newsletterPopupShown");

    // Only show the popup if it's the homepage and hasn't been shown in this session
    if (location.pathname === "/" && !isPopupShown) {
      const timer = setTimeout(() => {
        setIsVisible(true);
        sessionStorage.setItem("newsletterPopupShown", "true"); // Mark as shown
      }, 10000); // Show after 20 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [location.pathname]);

  const closePopup = () => {
    setIsVisible(false); // Hide the popup
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value;
    const email = e.target.elements.email.value;

    const apiKey = "mc5E05u5e4O7IlLiRJhm5w";
    const formId = "7263348";

    try {
      const response = await axios.post(
        `https://api.convertkit.com/v3/forms/${formId}/subscribe`,
        {
          api_key: apiKey,
          email: email,
          first_name: name,
        }
      );

      if (response.data.subscription) {
        alert("You have successfully subscribed!");
      } else {
        alert("Subscription failed. Please try again.");
      }
    } catch (error) {
      alert("Error occurred. Please try again later.");
    }
  };

  return (
    isVisible && (
      <div
        className="fixed bottom-4 right-4 bg-white shadow-lg border border-gray-300 p-6 rounded-lg max-w-sm w-full font-playfair"
        style={{
          zIndex: 1000, // Ensure the popup is on top
        }}
      >
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-black"
          onClick={closePopup}
        >
          ×
        </button>
        <h3 className="text-lg font-semibold mb-2">OUR NEWSLETTER</h3>
        <p className="text-sm text-gray-600 mb-4">
        We’ll send information to elevate your brand’s communication and marketing, and you do not have to pay anything
        </p>
        <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Enter your name"
            className="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            className="border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <button
            type="submit"
            className="bg-[#14143a] text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Subscribe
          </button>
        </form>
        <p className="text-sm text-gray-600 mb-4">
        By entering your email you agree to receive updates, promotions and our latest discoveries.        </p>

        <p
          className="text-xs text-gray-500 text-center"
          style={{ fontSize: "10px" }}
        >
          Please read our{" "}
          <Link to="/privacy-policy" className="text-blue-500 underline">
            Privacy Policy
          </Link>{" "}
          to see how we use your personal information.
        </p>
      </div>
    )
  );
};

export default NewsletterPopup;
