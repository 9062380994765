import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaChevronDown } from "react-icons/fa";
import Team from "../../Images/Evolved/team_leads.webp";
import KickLogo from "../../Images/KickLogo/navbar_logo.png";

const Career = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  // const [filters, setFilters] = useState({
  //   region: "All",
  //   location: "All",
  //   department: "All",
  //   company: "All",
  // });

  return (
    <>
      <section
        className="relative text-white py-16 px-3 sm:px-8 font-playfair w-full overflow-hidden"
        style={{
          background: "linear-gradient(135deg, #0c145b, #737373, #002772)",
        }}
      >
        {/* Large outlined "CAREERS" text */}
        <h1
    
          className="absolute top-32 lg:top-44 left-1/2 transform -translate-x-1/2 text-3xl sm:text-[1rem] md:text-[2rem] lg:text-[4rem] font-bold tracking-wide uppercase text-gray-300"
        >
          CAREERS
        </h1>

        <div
          className="max-w-6xl mx-auto flex flex-col lg:flex-row items-center gap-0 lg:gap-12 mt-20 lg:mt-44 px-4"
          data-aos="fade-up"
        >
          {/* Image */}
          <img
            src={KickLogo}
            alt="Acolyte"
            className="w-full max-w-xs sm:max-w-md lg:w-1/2"
            data-aos="fade-right"
          />

          {/* Text Content */}
          <div className="lg:w-1/2" data-aos="fade-left">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 border-b border-white pb-2">
              Who We Are
            </h2>
            <p className="text-base sm:text-lg leading-relaxed">
              Kick & Co is a team of believers who understand the necessity of
              properly representing narratives.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed mt-4">
              We provide communication and marketing solutions for builders of
              tomorrow because we know what it means to kick against the current
              and defy the negative narratives tied to one’s time and identity.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed mt-4">
              Our mission to be the acolytes of change-makers stems from our own
              journey.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed mt-4">
              Revolutionary leaders inspire and influence with compelling
              narratives—rooted in their lived experiences—to craft legacies
              that shape the future.
            </p>
          </div>
        </div>

        <div
          className="max-w-6xl mx-auto flex flex-col-reverse lg:flex-row items-center gap-12 px-4 mt-10 lg:mt-0"
          data-aos="fade-up"
        >
          <div className="lg:w-1/2" data-aos="fade-right">
            <h2 className="text-2xl sm:text-3xl font-semibold mb-4 border-b border-white pb-2">
              Grow With Us
            </h2>

            <p className="text-base sm:text-lg leading-relaxed">
              At Kick & Co, we believe in growth that defies limitations. Just
              as we help change-makers shape their narratives, we invite you to
              grow with us—challenging norms, pushing boundaries, and amplifying
              impact.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed">
              Whether you're a visionary leader, a bold storyteller, or a
              builder of tomorrow, we are here to equip you with the tools and
              strategies to thrive. Together, we can rewrite narratives, spark
              transformation, and leave a lasting legacy.
            </p>
            <br />
            <p className="text-base sm:text-lg leading-relaxed">
              Join us in the journey of growth, where every step forward is a
              step toward shaping the future.
            </p>
            <br />
          </div>

          {/* Image */}
          <img
            src={Team}
            alt="Acolyte"
            className="w-full max-w-xs sm:max-w-md lg:w-1/2"
            data-aos="fade-left"
          />
        </div>

        {/* Quote Section */}
        <div className="flex justify-center mt-10 lg:mt-20 px-0 lg:px-4">
          <div
            className="font-kalu text-3xl sm:text-2xl lg:text-4xl xl:text-6xl font-semibold text-center max-w-4xl tracking-wide text-gray-300"
            data-aos="zoom-in"
          >
            Attitude is more important than talent here.
            <br /> <span className="font-bold">– Kalu Kalu</span>
          </div>
        </div>
      </section>

      <div
        className="max-w-6xl mx-auto px-4 sm:px-6 py-10"
        data-aos="fade-up"
      >
        {/* Header */}
        <h1 className="text-5xl sm:text-6xl lg:text-8xl font-bold tracking-tight text-gray-900 uppercase opacity-10 leading-none text-center">
          WORK WITH US
        </h1>

        {/* Filters */}
        <div className="mt-10" data-aos="fade-up">
          <h2 className="text-lg sm:text-xl font-semibold">Opportunities</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 border-b pb-2 mt-4 text-gray-900">
            {["Department"].map((filter, index) => (
              <div
                key={index}
                className="flex items-center gap-2 text-sm font-medium"
              >
                {filter}
                <FaChevronDown className="text-gray-600 cursor-pointer" />
                {/* <span className="text-blue-200 italic">
                  {filters[filter.toLowerCase()]}
                </span> */}
              </div>
            ))}
          </div>
        </div>

        {/* No Open Positions */}
        <div
          className="mt-6 text-center text-gray-600 text-lg font-medium"
          data-aos="fade-in"
        >
          No current open positions
        </div>
      </div>
    </>
  );
};

export default Career;
