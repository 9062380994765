import React, { useState, useEffect } from "react";
import KickImage from "../../Images/KickLogo/navbar_logo.png";
import { useNavigate } from "react-router-dom";

const Nav = () => {
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);

  useEffect(() => {
    // Delay the visibility to simulate a slower slide-in effect
    const timeout = setTimeout(() => setIsNavVisible(true), 100);
    return () => clearTimeout(timeout);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMouseEnter = () => {
    setIsResourcesOpen(true);
  };

  const handleMouseLeave = () => {
    setIsResourcesOpen(false);
  };

  return (
    <nav
    className={`max-w-7xl bg-white shadow-md absolute top-2 left-1/2 py-1 transform -translate-x-1/2 md:w-full w-11/12 z-50 mt-4 rounded-lg font-playfair transition-transform duration-[1200ms]  ${
      isNavVisible ? "translate-y-0" : "-translate-y-full"
    }`}
  >
  
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between h-14 md:h-16">
          {/* Left-hand side (Links on desktop) */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="/" className="hover:text-black text-xl font-playfair">
              Services
            </a>

            {/* Resources dropdown */}
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <button className="flex items-center hover:text-black">
                <span className="text-xl font-playfair">Resources</span>
                <svg
                  className={`w-4 h-4 ml-2 transform transition-transform duration-200 ${
                    isResourcesOpen ? "rotate-180" : "rotate-0"
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {/* Dropdown menu */}
              <div
                className={`absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md opacity-0 transform transition-all duration-300 ${
                  isResourcesOpen
                    ? "opacity-100 translate-y-0"
                    : "opacity-0 translate-y-5"
                }`}
              >
                <a
                  href="/ktn"
                  className="block px-4 py-2 hover:bg-gray-100 rounded-t-md font-playfair"
                >
                  KTN
                </a>
              </div>
            </div>
          </div>

          {/* Center logo */}
          <div className="absolute left-1/2 transform -translate-x-1/2">
          <a href="/">
              <img
                src={KickImage}
                alt="KICK & CO Logo"
                className="w-auto h-20 md:h-24" // Adjust height/width as needed
              />
            </a>
          </div>

          {/* Right-hand side (Partner button on desktop) */}
          <div className="hidden md:block">
  <button
    onClick={() => {
      navigate("/home#price"); // This will change the URL to /home#price
    }}
    className="bg-[#1d3072] text-white sm:text-base md:text-xl 2xl:text-2xl px-5 py-3 font-playfair rounded-md transition duration-300 transform hover:bg-[#949a84] hover:scale-105"
  >
    Partnership Offers
  </button>
</div>



          {/* Burger icon (mobile) */}
          <div className="md:hidden absolute right-4 top-1/2 -translate-y-1/2 flex items-center " onClick={toggleMenu}>
          <button className="hover:text-black">
              {isMenuOpen ? (
                <svg
                  className="w-10 h-10"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="w-10 h-10"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              )}
            </button>
          </div>

          <div
    className={`md:hidden absolute top-14 left-0 w-full bg-white shadow-2xl rounded-lg transition-all duration-300 transform 
      ${isMenuOpen ? "translate-y-0 opacity-100" : "translate-y-5 opacity-0 pointer-events-none"}`}
  >
    <div className="flex flex-col items-start px-6 py-6 space-y-4">
      <a href="/" className="text-2xl font-playfair hover:text-gray-700 transition-colors">
        Services
      </a>

      {/* Resources dropdown (mobile) */}
      <div className="relative w-full">
        <button
          className="flex items-center justify-between w-full text-2xl font-playfair hover:text-gray-700"
          onClick={() => setIsResourcesOpen(!isResourcesOpen)}
        >
          <span>Resources</span>
          <svg
            className={`w-4 h-4 transition-transform duration-200 ${isResourcesOpen ? "rotate-180" : "rotate-0"}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        </button>

        {/* Dropdown menu */}
        <div
          className={`mt-2 w-full transition-all duration-300 overflow-hidden 
            ${isResourcesOpen ? "max-h-40 opacity-100" : "max-h-0 opacity-0 pointer-events-none"}`}
        >
          <a href="/ktn" className="block px-4 py-3  font-playfair hover:text-gray-700 text-2xl">
            Ktn
          </a>
        </div>
      </div>

      <button
        onClick={() => navigate("/home#price")}
        className="w-full bg-[#1d3072] text-white py-3 text-xl font-playfair rounded-md hover:bg-gray-800 transition duration-300"
      >
        Partnership Offers
      </button>
    </div>
  </div>

        </div>
      </div>
    </nav>
  );
};

export default Nav;
