import React, { useState } from "react";
import { motion } from "framer-motion";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What services do Kick & Co provide?",
      answer:
        "We specialize in communication and marketing solutions for visionary leaders and businesses. Our services include personal branding for executives, company-wide communication strategies, thought leadership content, legacy curation, media outreach, and integrated content ecosystems. Whether you're an executive, a business, or both, we help you craft compelling narratives that drive influence and impact.",
    },
    {
      question: "Who is an ideal client for Kick & Co?",
      answer:
        "Our ideal clients are leaders and businesses shaping the future. This includes executives looking to build authority and influence, companies aiming to establish a strong brand presence, and thought leaders and changemakers seeking to leave a lasting legacy. If you're ready to invest in high-quality, strategy-driven communication and marketing, we're ready to partner with you.",
    },
    {
      question: "What industries does Kick & Co work with?",
      answer:
        "We have experience across a wide range of industries, including technology, finance, healthcare, consumer goods, professional services, and nonprofits. No matter your field, our team excels at tailoring communication and marketing strategies to suit your unique needs and target audience.",
    },
    {
      question: "How long does it take to see results?",
      answer:
        "The timeline for results depends on your goals and the services you select. While building a personal brand or transforming a company's communication strategy is a long-term investment, many clients begin to see measurable outcomes—like increased engagement, stronger positioning, and clearer messaging—within the first 3–6 months. We'll work with you to set realistic expectations and milestones.",
    },
    {
      question: "How can I get started with Kick & Co?",
      answer:
        "Getting started is easy! Simply click the 'Engage' button on our website and answer a few quick questions about your needs. From there, we’ll review your responses and reach out with next steps, including a proposal or scheduling a discovery call. Together, we’ll craft a strategy that aligns with your goals and vision.",
    },
  ];

  return (
    <>
      <div className="max-w-7xl mx-auto p-6 lg:p-10 bg-gray-50 font-playfair overflow-hidden">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 py-10">
          {/* Left Section */}
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1.5 }}
            viewport={{ once: true }}
          >
            <h2 className="text-3xl lg:text-4xl xl:text-6xl text-gray-900">
              FAQ
            </h2>
            <p className="mt-5 text-xl xl:text-2xl">
            Here are some common questions we get about working together
            </p>
          </motion.div>

          {/* Right Section */}
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 1.5 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            {faqData.map((item, index) => (
              <div key={index} className="border-t border-gray-300 pt-4">
                <div
                  className="flex justify-between items-center cursor-pointer text-gray-900"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="text-lg md:text-xl xl:text-2xl font-medium">
                    {item.question}
                  </h3>
                  <span className="text-lg font-bold">
                    {activeIndex === index ? "−" : "+"}
                  </span>
                </div>
                {activeIndex === index && (
                  <p className="mt-2 text-gray-700 xl:text-lg">{item.answer}</p>
                )}
              </div>
            ))}
          </motion.div>
        </div>
      </div>
      <hr className="border-t border-gray-300 mt-10 max-w-sm md:max-w-7xl mx-auto" />
      </>
  );
};

export default Faq;
