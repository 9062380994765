import DNAYP from "../../Images/blogpics/afroprenueship/DNAYP.webp";
import TFC from "../../Images/blogpics/afroprenueship/TFC.webp";
import UPTMM from "../../Images/blogpics/afroprenueship/UPTMM.webp";
import IS from "../../Images/blogpics/afroprenueship/IS.webp";
import CGPT from "../../Images/blogpics/afroprenueship/CGPT.webp";
import ERROR from "../../Images/blogpics/afroprenueship/ERROR.webp";
import BILLIONDOLLAR from "../../Images/blogpics/afroprenueship/Billiondollar.webp";
import EMUC from "../../Images/blogpics/afroprenueship/EMUC.webp";
import MAG from "../../Images/blogpics/afroprenueship/MAG.webp";
import MPP from "../../Images/blogpics/afroprenueship/MPP.webp";
import HGP from "../../Images/blogpics/afroprenueship/HGP.webp";
import PPP from "../../Images/blogpics/afroprenueship/PPP.webp";
import VSH from "../../Images/blogpics/afroprenueship/VSH.webp";
import SPG from "../../Images/blogpics/afroprenueship/SPG.webp";
import GSC from "../../Images/blogpics/afroprenueship/GSC.webp";
import UAR from "../../Images/blogpics/afroprenueship/UAR.webp";
import SSE from "../../Images/blogpics/afroprenueship/SSE.webp";
import HCC from "../../Images/blogpics/afroprenueship/HCC.webp";
import DM from "../../Images/blogpics/afroprenueship/DM.webp";
import HCG from "../../Images/blogpics/afroprenueship/HCG.webp";
import HWG from "../../Images/blogpics/afroprenueship/HWG.webp";
import HGSC from "../../Images/blogpics/afroprenueship/HGSC.webp";
import HCWB from "../../Images/blogpics/afroprenueship/HCWB.webp";
import ETLN from "../../Images/blogpics/afroprenueship/ETLN.webp";
import BHPGT from "../../Images/blogpics/afroprenueship/BHPGT.webp";
import TIB from "../../Images/blogpics/afroprenueship/TIB.png";
import CIPG from "../../Images/blogpics/afroprenueship/CIPG.webp";
import BPBG from "../../Images/blogpics/afroprenueship/BPBG.webp";
import SUNG from "../../Images/blogpics/afroprenueship/SUNG.webp";
import TSE from "../../Images/blogpics/afroprenueship/TSE.webp";
import WBGYB from "../../Images/blogpics/afroprenueship/WBGYB.webp";
import KLFGB from "../../Images/blogpics/afroprenueship/KLFGB.webp";
import HCCBPP from "../../Images/blogpics/afroprenueship/HCCBPP.webp";
import TYSTPG from "../../Images/blogpics/afroprenueship/TYSTPG.webp";
import TIP from "../../Images/blogpics/afroprenueship/TIP.webp";
import WRIG from "../../Images/blogpics/afroprenueship/WRIG.webp";
import CLTCR from "../../Images/blogpics/afroprenueship/CLTCR.webp";
import TCFIS from "../../Images/blogpics/afroprenueship/TCFIS.webp";
import HGCOA from "../../Images/blogpics/afroprenueship/HGCOA.webp";
import SSTEM from "../../Images/blogpics/afroprenueship/SSTEM.webp";
import CATGO from "../../Images/blogpics/afroprenueship/CATGO.webp";
import ETLTAB from "../../Images/blogpics/afroprenueship/ETLTAB.webp";
import WTECG from "../../Images/blogpics/afroprenueship/WTECG.webp";
import STABB from "../../Images/blogpics/afroprenueship/STABB.webp";
import TSYPT from "../../Images/blogpics/afroprenueship/TSYPT.webp";
import WTEWY from "../../Images/blogpics/afroprenueship/WTEWY.webp";
import TSSO from "../../Images/blogpics/afroprenueship/TSSO.webp";

const aPosts = [
  {
    title:
      "3 Success Stories of Entrepreneurs Who Partnered With Ghostwriters",
    date: "December 27, 2024",
    image: TSSO,
    url: "/ktn/afroprenuership/three-success-stories-of-entrepreneurs-who-partnered-with-ghostwriters",
  },
  {
    title:
      "From Idea to Publishing- What to Expect When you Work With a Ghostwriter",
    date: "December 20, 2024",
    image: WTEWY,
    url: "/ktn/afroprenuership/from-idea-to-publishing-what-to-expect-when-you-work-with a-ghostwriter",
  },
  {
    title:
      "Ready to Publish- 10 Signs You're Prepared to Hire a Ghostwriter",
    date: "December 13, 2024",
    image: TSYPT,
    url: "/ktn/afroprenuership/ten-signs-youre-prepared-to-hire-a-ghostwriter",
  },
  {
    title:
      "The Secret to a Bestselling Book in 2025: Why CEOs Choose to Work with Ghostwriters",
    date: "December 06, 2024",
    image: STABB,
    url: "/ktn/afroprenuership/the-secret-to-a-bestselling-book",
  },
  {
    title:
      "Why Top Executives Choose Ghostwriters",
    date: "November 29, 2024",
    image: WTECG,
    url: "/ktn/afroprenuership/why-top-executives-choose-ghostwriters",
  },
  {
    title:
      "How a Ghostwriter Can Help You Establish Thought Leadership Through a Book",
    date: "November 22, 2024",
    image: ETLTAB,
    url: "/ktn/afroprenuership/how-a-ghostwriter-can-help-you-establish-thought-leadership-through-a-book",
  },
  {
    title:
      "How to Create A Tempting Ghostwriting Offer",
    date: "November 15, 2024",
    image: CATGO,
    url: "/ktn/afroprenuership/how-to-create-a-tempting-ghostwriting-offer",
  },
  {
    title:
      "7 Proven Strategies To Earn More Money As A Ghostwriter",
    date: "November 08, 2024",
    image: SSTEM,
    url: "/ktn/afroprenuership/seven-proven-strategies-to-earn-more-money-as-a-ghostwriter",
  },
  {
    title:
      "How Ghostwriters Can Overcome Anxiety When Sending Their First Pitch",
    date: "November 01, 2024",
    image: HGCOA,
    url: "/ktn/afroprenuership/how-ghostwriters-can-overcome-anxiety-when-sending-their-first-pitch",
  },
  {
    title:
      "Turning Client Feedback into Success: Essential Tips for Ghostwriters",
    date: "October 25, 2024",
    image: TCFIS,
    url: "/ktn/afroprenuership/turning-client-feedback-into-success",
  },
  {
    title:
      "How to Create Long-Term Client Relationships as a Ghostwriter",
    date: "October 18, 2024",
    image: CLTCR,
    url: "/ktn/afroprenuership/how-to-create-long-term-client-relationships-as-a-ghostwriter",
  },
  {
    title:
      "Why Reading Is Important for Ghostwriters",
    date: "October 11, 2024",
    image: WRIG,
    url: "/ktn/afroprenuership/why-reading-is-important-for-ghostwriters",
  },
  {
    title:
      "Expert Tips to Publicize Your Book Effectively with a Ghostwriter",
    date: "October 04, 2024",
    image: TIP,
    url: "/ktn/afroprenuership/expert-tips-to-publicize-your-book",
  },
  {
    title:
      "Why Your Story Matters: How Ghostwriting Can Bring Your Life to the Page",
    date: "September 27, 2024",
    image: TYSTPG,
    url: "/ktn/afroprenuership/tell-your-story-the-power-of-ghostwriting",
  },
  {
    title:
      "How to Help Clients Choose the Best Publishing Path: A Comprehensive Guide for Ghostwriters",
    date: "September 20, 2024",
    image: HCCBPP,
    url: "/ktn/afroprenuership/how-to-help-clients-choose-the-best-publishing-path",
  },
  {
    title: "Key Lessons to Learn from Famous Ghostwritten Books",
    date: "September 13, 2024",
    image: KLFGB,
    url: "/ktn/afroprenuership/key-lessons-from-famous-ghostwritten-books",
  },
  {
    title: "Writing a Book is Great for Your Business",
    date: "September 06, 2024",
    image: WBGYB,
    url: "/ktn/afroprenuership/writing-a-book-is-great-for-your-business",
  },
  {
    title: "Top Skills Every Ghostwriter Should Have",
    date: "August 30, 2024",
    image: TSE,
    url: "/ktn/afroprenuership/top-skill-every-ghostwriter-should-have",
  },
  {
    title: "5 Signs You Need a Ghostwriter for Your Next Book",
    date: "August 23, 2024",
    image: SUNG,
    url: "/ktn/afroprenuership/five-signs-ghostwriters-need",
  },
  {
    title: "How Ghostwriters Can Help You Build Your Personal Brand",
    date: "August 16, 2024",
    image: BPBG,
    url: "/ktn/afroprenuership/build-your-personal-brand",
  },
  {
    title:
      "Ghostwriting Interviews: Conducting Effective Sessions With Clients ",
    date: "August 09, 2024",
    image: CIPG,
    url: "/ktn/afroprenuership/ghostwriting-interview",
  },
  {
    title: "Ghostwriting for Authors: Turning Ideas into Bestsellers",
    date: "August 02, 2024",
    image: TIB,
    url: "/ktn/afroprenuership/ghostwriting-for-authors",
  },
  {
    title: "Building a High-performing Ghostwriting Team",
    date: "July 26, 2024",

    image: BHPGT,
    url: "/ktn/afroprenuership/building_high_performing_ghostwriting_team",
  },
  {
    title:
      "How Ghostwriting Can Help You Establish Yourself as a Thought Leader",
    date: "July 19, 2024",

    image: ETLN,
    url: "/ktn/afroprenuership/establish-yourself-as-a-thought-leader",
  },
  {
    title: "How to Overcome Writer’s Block as A Ghostwriter",
    date: "July 12, 2024",

    image: HCWB,
    url: "/ktn/afroprenuership/overcome-writers-block",
  },
  {
    title: "How to Ghostwrite SEO Content That Ranks",
    date: "July 05, 2024",

    image: HGSC,
    url: "/ktn/afroprenuership/ghostwrite-seo-content",
  },
  {
    title: "How to effectively work with a ghostwriter",
    date: "June 27, 2024",

    image: HWG,
    url: "/ktn/afroprenuership/effectively-work-ghostwriter",
  },
  {
    title: "How to Attract High-Value Clients as a Ghostwriter",
    date: "June 21, 2024",

    image: HCG,
    url: "/ktn/afroprenuership/attract-highvalue-clients",
  },
  {
    title: "Debunking Myths About Crediting Ghostwriters",
    date: "June 17, 2024",

    image: DM,
    url: "/ktn/afroprenuership/debunking-myths",
  },
  {
    title: "How to Craft Compelling Content as a Ghostwriter",
    date: "June 07, 2024",

    image: HCC,
    url: "/ktn/afroprenuership/craft-compelling-content",
  },
  {
    title: "Seven Top Strategies to Excel As A Ghostwriter",
    date: "May 31, 2024",

    image: SSE,
    url: "/ktn/afroprenuership/seven-top-strategies",
  },
  {
    title: "Understanding Your Rights as A Ghostwriter",
    date: "May 24, 2024",

    image: UAR,
    url: "/ktn/afroprenuership/understanding-your-rights",
  },

  {
    title: "How Ghostwriters Shape Public Content",
    date: "May 17, 2024",

    image: GSC,
    url: "/ktn/afroprenuership/shape-public-content",
  },
  {
    title: "How to Vet and Select the Perfect Ghostwriter for Your Project",
    date: "May 10, 2024",

    image: SPG,
    url: "/ktn/afroprenuership/perfect-ghostwriter-project",
  },
  {
    title: "Value Sells, Handouts Don't: How To Build a Sustainable Business",
    date: "April 19, 2024",

    image: VSH,
    url: "/ktn/afroprenuership/build-sustainable-business",
  },
  {
    title:
      "The Power Of Playful Persistence: Why Entrepreneurs Need A Touch Of Childishness",
    date: "April 05, 2024",

    image: PPP,
    url: "/ktn/afroprenuership/powerful-playful-persistence",
  },

  {
    title: "How to Grow Your Business by Empowering Others",
    date: "March 29, 2024",

    image: HGP,
    url: "/ktn/afroprenuership/grow-your-business",
  },
  {
    title: "How to master pricing at premium",
    date: "March 01, 2024",

    image: MPP,
    url: "/ktn/afroprenuership/master-premium-pricing",
  },
  {
    title:
      "Mastering the Art of Ghostwriting: Essential Tips and Tricks for Success",
    date: "February 09, 2024",

    image: MAG,
    url: "/ktn/afroprenuership/mastering-the-art-of-ghostwriting",
  },
  {
    title:
      "Embracing Uncertainty: How visionary entrepreneurs navigate the unknown",
    date: "January 26, 2024",

    image: EMUC,
    url: "/ktn/afroprenuership/embracing-uncertainty",
  },

  {
    title: "What is your cash cow?",
    date: "October 12, 2023",

    image: BILLIONDOLLAR,
    url: "/ktn/afroprenuership/what-is-your-cash-cow",
  },

  {
    title: "Trial and error makes you wise",
    date: "September 17, 2023",

    image: ERROR,
    url: "/ktn/afroprenuership/trial-and-error",
  },
  {
    title: "Cold outreach (Human vs Artificial Intelligence)",
    date: "June 11, 2023",

    image: CGPT,
    url: "/ktn/afroprenuership/cold-outreach",
  },
  {
    title: "How to overcome imposter syndrome in any industry",
    date: "May 05, 2023",

    image: IS,
    url: "/ktn/afroprenuership/overcome-imposter-syndrome",
  },

  {
    title: "You don't need to have talent, have leverage",
    date: "February 17, 2023",

    image: UPTMM,
    url: "/ktn/afroprenuership/talent-leverage",
  },
  {
    title: "You can bill clients whatever you want",
    date: "May 25, 2023",

    image: TFC,
    url: "/ktn/afroprenuership/bill-clients",
  },
  {
    title:
      "Don’t Niche Away Your Profit: Why Diversification Is Key For Business Success",
    date: "June 06, 2023",

    image: DNAYP,
    url: "/ktn/afroprenuership/dont-niche-away-your-profit",
  },
  // Additional AI posts...
];

export default aPosts;
